import { render, staticRenderFns } from "./AjouterVentePro.vue?vue&type=template&id=580a8c86&scoped=true&"
import script from "./AjouterVentePro.vue?vue&type=script&lang=js&"
export * from "./AjouterVentePro.vue?vue&type=script&lang=js&"
import style0 from "./AjouterVentePro.vue?vue&type=style&index=0&id=580a8c86&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "580a8c86",
  null
  
)

export default component.exports